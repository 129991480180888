// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
/// @see {mixin} respond-to
$breakpoints: (
    'extra-small': (max-width: 320px),
    'small': (max-width: 480px),
    'medium': (max-width: 768px),
    'large': (max-width: 1024px),
) !default;


///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '../' !default;

///   $base-url: 'http://cdn.example.com/assets/img/responsive/some_image.png';
$images-url: $base-url + 'img/' !default;
$fonts-url: $base-url + 'fonts/' !default;