header {
    width: 100%;
    // height: 440px; 
    position: relative;
    overflow: hidden;

    .logo {
        margin: 0 auto;
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        display: block;
        z-index: 9;
        
        @media (max-width: 768px) {
            top: 64px;
        }
    }

    .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 25px;
        width: 80px;
        padding-left: 0;

        li {
            width: 13px;
            height: 13px;
            background: #90e0f4;
            margin-right: 9px;
            display: inline-block;
            border-radius: 50%;
            cursor: pointer;

            &:last-of-type {
                margin-right: 0;
            }

            &.slick-active {
                background: #FFF;
            }

            button {
                display: none;
            }
        }
    }

    .carousel {
        width: 100%;
        height: 440px;

        .carousel-item {
            width: 100%;
            height: 440px;
            display: block;
            float: left;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            &.second {
                p {
                    max-width: 600px;
                    margin: 190px auto 0;
                }
            }
        }

        p {
            font-size: 40px;
            font-weight: 900;
            display: block;
            text-align: center;
            margin-top: 190px;
            color: #FFF;
            line-height: 1.2;

            @media (max-width: 768px) {
                font-size: 20px;
                padding: 0 45px;
            }
        }

        span {
            font-size: 18px;
            display: block;
            text-align: center;
            margin-top: 10px;
            color: #FFF;

            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
    }
}

.anchor-nav-wrapper {
    width: 100%;
    background-color: white;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(214,211,214,0.5);
    -moz-box-shadow: 0px 2px 10px 0px rgba(214,211,214,0.5);
    box-shadow: 0px 2px 10px 0px rgba(214,211,214,0.5);
    top: 0;
    z-index: 10;
    position: fixed; // to take place for older browsers
    position: sticky; //so it takes its height into acount
    position: -webkit-sticky; //so it works on Safari too

    @media (max-width: 768px) { 
        padding: 10px 15px;
        position: fixed;
    }
    
    .anchor-nav {
        display: block;
        height: 68px;
        margin: 0 auto;
        max-width: 1105px;
        text-align: center;

        @media (max-width: 768px) { 
            display: none;
            height: calc(100vh - 45px);
        }
        ul {
            margin-bottom: 0;
            padding-left: 0;
            li {
                display: inline-block;
                list-style: none;
                list-style-type: none;
                list-style-position: outside;
                overflow: hidden;

                
                @media (max-width: 768px) { 
                    display: block;
                }
                a {
                    display: block;
                    padding: 0 15px;
                    font-size: 15px;
                    font-weight: 700;
                    color: #3ec7f3;
                    line-height: 68px;
                    font-family: "Roboto";
                    transition: all 0.3s ease;
                    text-transform: uppercase;
                    
                    @media (max-width: 768px) { 
                        line-height: 38px;
                    }

                    &:hover,
                    &.active {
                        color: white;
                        background-color: #3ec7f3;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }

    .anchor-mobile-burger {
        display: none;
        width: 45px;
        height: 30px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        @media (max-width: 768px) { 
            display: block;
            width: 35px;
            height: 25px;
        }

        span {
            display: block;
            position: absolute;
            z-index: 20;
            height: 6px;
            width: 100%;
            background: #3ec7f3;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;

            @media (max-width: 768px) { 
                height: 5px;
            }

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 12px;
                @media (max-width: 768px) { 
                    top: 10px;
                }
            } 

            &:nth-child(4) {
                top: 24px;
                @media (max-width: 768px) { 
                    top: 20px;
                }
            }
        }

        &.opened {
        
            margin-bottom: 10px;
            span {

                &:nth-child(1) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
                &:nth-child(2) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
}