* {
    font-family: "Roboto";
}

.slick-prev, .slick-next {
    position: absolute;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: 0;
    color: transparent;
    padding: 0;
    width: 45px;

    @media (max-width: 768px) {
        width: 35px;
    }
}

.slick-prev {
    left: 5px;
}

.slick-next {
    right: 5px;
}

.slick-next {
    &:before {
        content: "\203A";
        display: block;
        color: #FFF;
        font-size: 65px;
        position: relative;
        z-index: 1000;
    }
}

.slick-prev {
    &:before {
        content: "\2039";
        display: block;
        color: #FFF;
        font-size: 65px;
        position: relative;
        z-index: 1000;
    }
}

.slick-next, .slick-prev {
    &:active, &:focus {
        outline: 0;
    }
}
.row {
    max-width: 1105px;
    margin: 0 auto;
}

h2 {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 30px;
    display: block;
    text-align: center;
    color: #333;

    @media (max-width: 768px) {
        font-size: 23px;
    }
}

.c-product {
    padding: 60px 0 40px;

    @media (max-width: 768px) {
        padding: 30px 15px;
    }

    .sub-headline {
        font-size: 14px;
        color: #3ec7f3;
        font-family: "Roboto";
        font-weight: 900;
        letter-spacing: 0.3em;
        display: block;
        text-align: center;
        text-transform: uppercase;
        margin-top: 40px;
        margin-bottom: 40px;

        @media (max-width: 768px) {
            font-size: 12px;
            margin: 15px 0;
        }
    }

    p {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: #333;
        display: block;
        text-align: left;
        margin: 0 auto;

        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 22px;
        }

        &:nth-of-type(1) {
            max-width: 930px;
            margin-bottom: 40px;
        }

        &:nth-of-type(2) {
            // max-width: 740px;
            max-width: 930px;
            margin-bottom: 60px;
        }

        @media (max-width: 768px) {
            margin-bottom: 15px;
        }
    }

    ul {
        list-style: none;

        li {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 16px;
            line-height: 1.2;
            margin-bottom: 40px;
            color: #6b767c;
            position: relative;
        }

        &.column-one {
            li {
                &:nth-of-type(1) {
                    &:before {
                        content: "\f05b";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 24px;
                        color: #3ec7f3;
                        display: inline-block;
                        position: absolute;
                        top: -4px;
                        left: -35px;
                    }
                }
                &:nth-of-type(2) {
                    &:before {
                        content: "\f0c9";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 24px;
                        color: #3ec7f3;
                        display: inline-block;
                        position: absolute;
                        top: -4px;
                        left: -35px;
                    }
                }

                &:nth-of-type(3) {
                    &:before {
                        content: "\f200";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 24px;
                        color: #3ec7f3;
                        display: inline-block;
                        position: absolute;
                        top: -4px;
                        left: -37px;
                    }
                }

                &:nth-of-type(4) {
                    &:before {
                        content: "\f036";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 24px;
                        color: #3ec7f3;
                        display: inline-block;
                        position: absolute;
                        top: -4px;
                        left: -37px;
                    }
                }

                &:nth-of-type(5) {
                    &:before {
                        content: "\f3a5";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 400;
                        font-size: 24px;
                        color: #3ec7f3;
                        display: inline-block;
                        position: absolute;
                        top: -4px;
                        left: -37px;
                    }
                }
            }
        }

        &.column-two {
            li {
                &:nth-of-type(1) {
                    &:before {
                        content: "\f1d8";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 24px;
                        color: #3ec7f3;
                        display: inline-block;
                        position: absolute;
                        top: -4px;
                        left: -36px;
                    }
                }
                &:nth-of-type(2) {
                    &:before {
                        content: "\f140";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 24px;
                        color: #3ec7f3;
                        display: inline-block;
                        position: absolute;
                        top: -4px;
                        left: -35px;
                    }
                }

                &:nth-of-type(3) {
                    &:before {
                        content: "\f005";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 24px;
                        color: #3ec7f3;
                        display: inline-block;
                        position: absolute;
                        top: -4px;
                        left: -37px;
                    }
                }

                &:nth-of-type(4) {
                    &:before {
                        content: "\f080";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 24px;
                        color: #3ec7f3;
                        display: inline-block;
                        position: absolute;
                        top: -4px;
                        left: -37px;
                    }
                }

            }
        }
    }
}

.c-line {
    width: 100%;
    height: 60px;
    background: #3ec7f3;

    span {
        display: block;
        text-align: center;
        line-height: 60px;
        font-family: "Roboto";
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        color: #FFF;
        letter-spacing: 0.3em;

        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
}

.c-meeting {
    padding: 60px 0 40px;
    margin-bottom: 10px;
    background: #ecf7fb;

    @media (max-width: 768px) {
        padding: 30px 15px;
    }

    .col-md-6 {
        &:last-of-type {
            padding-right: 25px;
        }
    }

    h2 {
        margin-bottom: 60px;

        @media (max-width: 768px) {
            margin-bottom: 15px;
        }
    }

    img {
        margin-top: 20px;

        @media (max-width: 768px) {
            display: block;
            margin: 20px auto;
        }
    }

    span {
        font-size: 16px;
        line-height: 36px;
        display: block;
        color: #333;
        font-weight: 500;
        margin-bottom: 8px;


    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 14px;
            color: #6b767c;
            font-weight: 300;
            position: relative;

            &:before {
                content: "";
                display: block;
                background: url('../img/icon-arrow.png') center center no-repeat;
                background-size: contain;
                width: 7px;
                height: 11px;
                position: absolute;
                top: 4px;
                left: -15px;
            }
        }
    }
}

.c-benefits {
    span {
        font-size: 25px;
        line-height: 30px;
        font-family: "Roboto";
        font-weight: 300;
        display: block;
        height: 130px;

        @media (max-width: 768px) {
            font-size: 22px;
            height: auto;
            margin-bottom: 15px;
        }
    }

    .col-md-6 {
        padding-right: 55px !important;

        @media (max-width: 768px) {
            padding-right: 0 !important;
        }
    }
}

.case-studies {
    padding: 60px 0;

    @media (max-width: 768px) {
        padding: 30px 0;
    }

    h2 {
        margin-bottom: 60px;

        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
    }

    p {
        font-weight: 300;
        font-size: 24px;
        line-height: 30px;
        color: #333;
        letter-spacing: -0.021em;

        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 22px;
        }

        &:last-of-type {
            margin-bottom: 30px;
        }
    }

    ul {
        list-style-type: none;
        padding-left: 16px;

        li {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 14px;
            color: #6b767c;
            font-weight: 300;
            position: relative;

            &:before {
                content: "";
                display: block;
                background: url('../img/icon-arrow.png') center center no-repeat;
                background-size: contain;
                width: 7px;
                height: 11px;
                position: absolute;
                top: 4px;
                left: -15px;
            }
        }
    }

    img {
        display: block;
        text-align: center;
        margin: 0 auto;
    }

    .quote {
        margin-top: 20px;
    }

    .row {
        &:nth-of-type(2), &:nth-of-type(3) {
            margin-top: 120px;

            @media (max-width: 768px) {
                margin-top: 40px;
            }
        }

        &:nth-of-type(2) {
            @media (max-width: 768px) {
                p {
                    margin-top: 30px;
                }
            }
        }
    }
}

.services {
    background: #3ec7f3;
    padding: 60px 0 67px;

    @media (max-width: 768px) {
        padding: 30px 0 35px;
    }

    h2 {
        color: #fff;
        margin: 0 0 60px;

        @media (max-width: 768px) {
            margin: 0 0 30px;
        }
    }

    img, p {
        float: left;
    }

    p {
        width: calc(100% - 170px);
        margin-left: 27px;
        color: #FFF;
        font-weight: 300;
        font-size: 16px;
        line-height: 25px;
    }
}

.about {
    padding: 60px 0;
    background: url('../img/about-bg.jpg') center center no-repeat;
    background-size: cover;

    @media (max-width: 768px) {
        padding: 30px 0;
    }

    h2 {
        color: #FFF;
        margin: 0 0 60px;

        @media (max-width: 768px) {
            margin: 0 0 30px;
        }
    }

    div.text {
        max-width: 825px;
        margin: 0 auto;
        text-align: center;
        position: relative;
    }

    div.quote {
        content: "";
        width: 38px;
        height: 32px;
        background: url('../img/icon-quote.png') center center no-repeat;
        background-size: contain;
        position: absolute;
        left: 50%;
        top: -19px;
        transform: translateX(-50%);

        @media (max-width: 768px) {
            width: 28px;
            height: 24px;
            top: -14px;
        }
    }

    p {
        font-weight: 700;
        font-size: 21px;
        line-height: 28px;
        color: #FFF;
        text-align: center;
        margin: 0 auto;
        position: relative;
        z-index: 9;

        @media (max-width: 768px) {
            font-size: 15px;
            line-height: 21px;
        }
    }
}

footer {
    background: #ecf7fb;
    padding: 77px 0 0;

    @media (max-width: 768px) {
        padding: 30px 0 0;
    }

    .col-md-8 {
        span {
            font-size: 18px;
            line-height: 20px;
            color: #333;
            margin-bottom: 24px;
            display: block;

            @media (max-width: 768px) {
                line-height: 1.2;
            }

            strong {
                font-size: 30px;
                font-weight: 400;
                color: #3ec7f3;

                @media (max-width: 768px) {
                    display: block;
                    margin-bottom: 5px;
                }
            }
        }

        input[type="text"], textarea {
            width: 100%;
            height: 48px;
            background: #FFF;
            border: 1px solid #dee7ea;
            margin-bottom: 13px;
            max-width: 680px;
            border-radius: 6px;
            padding: 0 20px;
            resize: none;

            &:focus, &:active {
                border: 1px solid #c9d1d3;
                outline: 0;
            }

        }

        textarea {
            margin-bottom: 9px;
        }

        input[type="submit"] {
            width: 100%;
            height: 48px;
            background: #3ec7f3;
            margin-bottom: 17px;
            max-width: 680px;
            border-radius: 6px;
            padding: 0 20px;
            resize: none;
            color: #FFF;
            font-weight: 900;
            text-align: center;
            border: 0;

            &:focus, &:active {
                outline: 0;
            }
        }

        textarea {
            height: auto;
        }
    }

    .col-md-4 {
        @media (max-width: 768px) {
            margin-top: 30px;
        }

        span {
            font-size: 30px;
            color: #333;
            display: block;
            margin-bottom: 10px;
            margin-top: -13px;

            @media (max-width: 768px) {
                font-size: 22px;
            }

            &:nth-of-type(2) {
                margin-top: 40px;
            }
        }

        p {
            font-size: 18px;
            line-height: 30px;
            color: #6b767c;

            @media (max-width: 768px) {
                font-size: 14px;
                line-height: 22px;
            }
        }

        .socials {
            a {
                font-size: 23px;
                margin-right: 20px;

                &:nth-of-type(1) {
                    color: #6e72b0;
                }

                &:nth-of-type(2) {
                    color: #05baf6;
                }

                &:nth-of-type(3) {
                    color: #ff0000;
                }

                &:nth-of-type(4) {
                    color: #378ebe;
                }

                &:nth-of-type(5) {
                    color: #ff0048;
                }

                &:nth-of-type(6) {
                    color: #0d0d0d;
                }

                &:nth-of-type(7) {
                    color: #da4229;
                }
            }
        }
    }

    .sub-footer {
        border-top: 1px solid #d8e2ed;
        font-size: 12px;
        line-height: 75px;
        margin-top: 40px;
        color: #6b767c;

        @media (max-width: 768px) {
            text-align: center;
        }

        a {
            color: #6b767c;
        }

        .col-md-4 {
            @media (max-width: 768px) {
                margin-top: 0;
            }
        }

        img {
            float: right;
            margin-top: 17px;

            @media (max-width: 768px) {
                float: none;
                display: block;
                margin: 0 auto 20px;
            }
        }
    }
}